import Swiper from "swiper";

export default class Tides {

    static #tides_slider         = null;

    constructor()
    {
        if ( window.matchMedia( "(max-width: 650px)" ).matches ) {
            Tides.slider_tides();

            const trigger = '[data-trigger="tides-month-item"]';

            $( document ).on( 'click', trigger, function ( e ) {
                e.preventDefault();
                Tides.slider_tides();
            } );
        }
    }

    static slider_tides()
    {
        let element = '[data-id="tides-slider"].active';
        let item = '[data-id="tides-slider"].active [data-id="tides-slider-item"]';

        // Reset Swiper
        if(Tides.#tides_slider){
            Tides.#tides_slider.destroy();
            Tides.#tides_slider = null;
        }

        if( !$(element).hasClass('swiper-container') ) {
            $(element).addClass('swiper-container');
            $(item).wrapAll('<div class="swiper-wrapper"></div>').addClass('swiper-slide');
        }

        if ( element.length > 1 ) {
            let options = {
                speed: 250,
                navigation: {
                    nextEl: '[data-slider-dot="1"]',
                    prevEl: '[data-slider-dot="0"]',
                    disabledClass: 'active'
                },
                spaceBetween: 20,
                mousewheel: {
                    forceToAxis: true,
                },
                grabCursor: true
            }

            Tides.#tides_slider = new Swiper(element, options);
        }
    }
}
