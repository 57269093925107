import '@nestor/scss/app.scss';
import '@nestor/scss/app-editor.scss';
import '@nestor/scss/app-weather.scss';
import '@nestor/scss/app-snow.scss';
import '@nestor/scss/app-tides.scss';
import '@nestor/scss/app-webcams.scss';
import '@nestor/scss/app-pour-vous.scss';
import '@nestor/scss/app-blog.scss';
import '@nestor/scss/app-cms.scss';
import '@nestor/scss/app-contact.scss';
import '@nestor/scss/app-404.scss';
import '@nestor/scss/app-search.scss';
import '@nestor/scss/app-account.scss';
import '@nestor/scss/app-profile.scss';
import '@nestor/scss/app-travel-book.scss';
import '@nestor/js/bootstrap.bundle';
import Router from '@nestor/js/utils/router.js';
import common from '@nestor/js/routes/common.js';
import pageTemplateWebcams from '@nestor/js/routes/pageTemplateWebcams.js';
import pageTemplateWeather from '@nestor/js/routes/pageTemplateWeather.js';
import pageTemplateTides from '@nestor/js/routes/pageTemplateTides.js';

const routes = new Router( {
                               common,
                               pageTemplateWebcams,
                               pageTemplateWeather,
                               pageTemplateTides
} );

document.addEventListener( "DOMContentLoaded", () => {
    routes.loadEvents();
} );
