import Webcam from '@nestor/js/services/webcam.js';

export default {
    init()
    {
        new Webcam();
    },
    finalize()
    {
        // Finalize
    },
};
