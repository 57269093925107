import Tides from '@nestor/js/services/tides.js';

export default {
    init()
    {
        new Tides();
    },
    finalize()
    {
        // Finalize
    },
};
