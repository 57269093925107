export default class Weather {

	constructor() {
		Weather.initialization();
	}

	static initialization() {
		const $elements = $('[data-id="wpiris-weather"]');

		$elements.each(function () {
			const elem = $(this);
			const trigger = elem.find('[data-trigger="wpiris-weather-toggle"]');

			trigger.click(function (e) {
				e.preventDefault();
				
				if (elem.hasClass('open')) {
					elem.removeClass('open').find('.weatherItemDetail').slideUp('500');
				} else {
					elem.addClass('open').find('.weatherItemDetail').slideDown('500');
				}
				
				let findEl = $( elem ).hasClass( 'open' );
				
				if ( findEl === true ) {
					$( this ).parents( '.weatherList' ).addClass( 'itemIsOpen' );
				} else {
					$( this ).parents( '.weatherList' ).removeClass( 'itemIsOpen' );
				}
				return false;
			});
		});
	}
}
