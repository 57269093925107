import Hls from "hls.js";

export default class Webcam {
    
    constructor()
    {
        Webcam.initialization();
        Webcam.hls_support();
    }
    
    static initialization()
    {
        const element = '[data-id="wpiris-webcam-main"]';
        const trigger = '[data-trigger="wpiris-webcam-item"]';
        
        $( document ).on( 'click', trigger, function ( e ) {
            let id   = $( this ).attr( 'data-webcam-id' );
            let type = $( this ).attr( 'data-webcam-type' );
            
            if ( id != $( element ).attr( 'data-webcam-id' ) ) {
                $(trigger).removeClass('disabled');
                $(this).addClass('disabled');
                Webcam.build_media( id, type, element );
            }
        } );
    }
    
    static build_media( id, type, element )
    {
        $( element ).addClass( 'loading' );
        $.ajax(
            {
                url: Theme.ajaxURL,
                data: {
                    'action': 'build_main_webcam',
                    'id': id,
                },
                method: "POST",
            }
        ).done( function ( response ) {
            $( element ).attr( 'data-webcam-id', id );
            $( element ).attr( 'data-webcam-type', type );
            $( element ).html( response );
            Webcam.hls_support();
            $( element ).removeClass( 'loading' );
        } );
    }
    
    static hls_support()
    {
        const $element = $( '[data-id="wpiris-webcam-main"]' );
        let type       = $element.attr( 'data-webcam-type' );
        
        if ( type == 'video-hls' ) {
            let video     = $element.find( 'video' ).get( 0 );
            let video_src = $element.find( 'source' ).attr( 'src' );
            
            if ( Hls.isSupported() ) {
                let hls = new Hls();
                hls.attachMedia( video );
                hls.on( Hls.Events.MEDIA_ATTACHED, function () {
                    hls.loadSource( video_src );
                    hls.on( Hls.Events.MANIFEST_PARSED, function () {
                        video.play();
                    } );
                } );
            } else if ( video.canPlayType( 'application/vnd.apple.mpegurl' ) ) {
                video.src = video_src;
                video.addEventListener( 'loadedmetadata', function () {
                    video.play();
                } );
            }
        }
    }
}
