import Utils from '@nestor/js/services/utils';
import Form from '@nestor/js/services/form';
import Slider from '@nestor/js/services/slider.js';
import Ux from '@nestor/js/services/ux.js';
import Speech from '@nestor/js/services/speech.js';
import ClearCache from '@nestor/js/services/clear-cache';
import Overlay from '@nestor/js/services/overlay.js';

export default {
    init()
    {
        new Utils();
        new Ux();
        new Form();
        new Slider();
        new Speech();
        ClearCache.font_icon();
        new Overlay();
    },
    finalize()
    {
        // Finalize
    },
};
