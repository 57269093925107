import Weather from '@nestor/js/services/weather';

export default {
    init()
    {
        new Weather();
    },
    finalize()
    {
        // Finalize
    },
};
